<template>
    <div v-loading="loading" class="right-content">
        <div class="mod-block" style="margin-top: 20px;">
            <span>院校类别</span>
            <div class="type-block">
                <div :class="education===0?'select':''" @click="education=0">中职</div>
                <div :class="education===1?'select':''" @click="education=1">高职</div>
                <div :class="education===2?'select':''" @click="education=2" style="border-right:0px">职业本科</div>
            </div>
        </div>
        <div class="mod-block" style="margin-top: 20px;">
            <span>学校名称</span>
            <input v-model="name"/>
        </div>
        
        <div class="mod-block">
            <span>所属地</span>
            <el-cascader
                v-model="areaName"
                :props="areaProps"
                :options="options"
                placeholder="请选择所在区域"
            />
        </div>
        
        <div class="btn" @click="next">合作院校申请</div>
    </div>
</template>
<script>
import { Cascader } from 'element-ui';
import options from '../assets/utils/areaData.js';
export default {
    components: {
        'el-cascader': Cascader,
    },
    props:{
       
    },
    data() {
        return {
            loading: false,
            name:'',
            place:'',
            education: 0,
            areaName:[],

            options: options, // 区域选择数据
            areaProps: {
                value: 'value',
                label: 'label',
                children: 'children',
            },
        }
    },
    methods: {
        async next(){
            if(this.name === ''){
                this.$toast('请输入学校名称');
                return false;
            }
            if(this.areaName.length !== 2){
                this.$toast('请输入所属地');
                return false;
            }
            const params = {
                name: this.name,
                province: this.areaName[0],
                city: this.areaName[1],
                education: this.education
            }
            
            try {
                this.loading = true;
                const res = await this.$http.post('/api/user/addSchool', params)
                this.loading = false;
                if(res){
                    this.$toast('提交成功');
                    window.location.reload();
                }
            } catch (error) {
                this.loading = false;
                this.$toast(error.message||'请求失败');
            }
            
        }
    },
}
</script>
<style lang="scss" scoped>
::v-deep{
    .el-cascader .el-input .el-input__inner {
        text-overflow: ellipsis;
        height: 22px;
        width: 219px;
        border-color: rgb(118, 118, 118);
    }
}
input{
    width:210px;
}
.right-content{
    width: 600px;
    .mod-block{
        span{
            display: inline-block;
            width: 65px;
        }
        width:300px;
        margin:auto;
        margin-bottom: 10px;
        font-size: 14px;
        color: #666666;
        position: relative;
        .type-block{
            width: 217px;
            display: flex;
            box-sizing: border-box;
            border: 1px solid #767676;
            position: absolute;
            top: -2px;
            left: 66px;
            div{
                cursor: pointer;
                width:33.333%;
                text-align: center;
                height: 21px;
                line-height: 21px;
                box-sizing: border-box;
                border-right: 1px solid rgb(118, 118, 118);
            }
            .select{
                background: #3884D3 !important;
                color: #fff !important;
            }
        }
    }
}
.btn{
    width: 100px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #3884D3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    margin-top:20px;
    cursor: pointer;

}
</style>
