<template>
    <div class="info-list">
        <Header/>
        <HeaderTitle title="院校资源库"/>
        <div class="blank20"/>
        <div class="main-page-content contain-left-right">
            <div class="block2">
                <div v-for="(item, index) in arr" :key="index" class="row">
                    <span class="name">{{item.name}}</span>
                    <span class="site"><span class="site-info">{{item.province}}{{item.city}}</span> <span class="type">基础院校</span></span>
                </div>
            </div>
            <RightContent />
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
import RightContent from './yuanxiaoRightContent';
export default {
    data() {
        return {
            arr:[],
        }
    },
    components:{
        RightContent,
    },
    beforeMount() {
        this.$http.get('/api/index/getSchoolList',{is_index:1}).then(res => {
            this.arr = res||[];
            this.$forceUpdate();
        })
    },
}
</script>
<style lang="scss" scoped>
.block2{
    display: flex;
    width: 600px;
    align-items: flex-start;
    flex-flow: wrap;
    .row{
        width:50%;
        margin-bottom: 15px;
        .name{
            font-size: 14px;
            color:#000;
            width: 95%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            font-weight: bold;
        }
        .site{
            margin-top: 5px;
            font-size: 13px;
            color:#000;
            width: 100%;
            display: block;
            position: relative;
            .site-info{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                display: block;
                width: 65%;
            }
            .type{
                position: absolute;
                right: 20px;
                bottom: 0px;
                color: #FF0000;
                font-weight: bold;
            }
        }
    }
}
</style>
